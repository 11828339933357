#faq {
    margin-top: 0;
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: var(--faq-item-bg-color);
    width: 100vw;
    transform: translateX(-5%);
}

#faq h2 {
    /* font-family: 'Inconsolata', monospace; */
    background-color: transparent;
    display: block;
    text-align: center;
}

.faq-questions-container {
    display: flex;
    gap: 2rem;
}

.faq-group {
    width: 50%;
}

@media screen and (max-width: 768px) {
    .faq-group {
        width: 100%;
    }

    .faq-questions-container {
        flex-direction: column;
        gap: 0;
    }

    .faq-item:first-child {
        margin-top: 0;
    }

}
