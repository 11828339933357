#root .contact {
    margin: 15rem 25% 15rem 25%;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
}

.contact form {
    width: 100%;
}

.contact h1 {
    font-size: 2rem;
    margin-bottom: 3rem;
}

.contact form span {
    font-family: 'Helvetica-W01-Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

.contact form h2 {
    background-color: transparent;
    font-size: 1rem;
    padding: 0;
    font-weight: 500;
}

.contact form .name-inputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.contact form .name-inputs label {
    font-size: 0.8rem;
    font-weight: 400;
}

.contact form .name-inputs input {
    margin-top: 0.25rem;
}

.contact form input {
    height: 3rem;
    font-size: 1.25rem;
}

.contact form textarea {
    font-size: 1rem;
    height: 20rem;
    max-width: 100%;
}

.contact form input, .contact form textarea {
    width: 100%;
    margin: 1rem 0;
}

.contact form input[type="submit"] {
    background-color: black;
    color: white;
    width: 130px;
    padding: 0.5rem 0;
    font-size: 1rem;
}

@media screen and (max-width:1440px) {
    #root .contact {
        margin: 15rem 15% 15rem 15%;
    }
}

@media screen and (max-width:1024px) {
    #root .contact {
        margin: 15rem 0 15rem 0;
    }
}