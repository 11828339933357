#dashboard {
    width: 100vw;
    height: 100vh;
    padding: 0rem;
    display: grid;
    place-items: center;
    box-sizing: border-box;
    background-color: #160B03;
}

#dashboard h2 {
    background-color: transparent;
    padding: 0;
    font-weight: 400;
}

#dashboard .mobile {
    display: none;
}

#dashboard-view {
    box-sizing: border-box;
    background-color: #160B03;
    width: 98%;
    height: 98%;
    border-radius: 2rem;
    display: grid;
    grid-template-columns: 10rem 1fr;
}

#dashboard-content {
    box-sizing: border-box;
    background-color: #f2f1f2;
    border-radius: 1rem;
    margin: 1rem;
    height: 95vh;
    background-color: #fff;
    overflow: hidden;
    position: relative;
}

#dashboard-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 1rem;
    padding: 3rem 0;
}

#dashboard-sidebar > * {
    width: 100%;
}

#dashboard-sidebar-main ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

#dashboard-sidebar-main ul li {
    margin: 1rem 0;
    width: 100%;
}

.dashboard-sidebar-menu-item, .dashboard-sidebar-menu-item > a {
    color: #777;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.dashboard-sidebar-menu-item svg {
    margin: 1rem 0 0 0;
}

.dashboard-sidebar-menu-item > a:hover {
    background-color: transparent;
}
.dashboard-sidebar-menu-item > a.active {
    box-sizing: border-box;
    border-right: white 5px solid;
    border-radius: 7px;
    color: white;
}

#dashboard-sidebar-logo {
    width: 90%;
}

#dashboard-sidebar img {
    width: 100%;
}

@media screen and (max-width: 768px) {
    #dashboard .mobile {
        display: block;
    }

    #dashboard .desktop {
        display: none;
    }

    #dashboard-view {
        width: 100%;
        height: 100%;
        border-radius: 0;
        position: relative;
    }


    .dashboard-sidebar-menu-item > a.active {
        border-right: none;
        border-bottom: white 5px solid;
    }

    #dashboard-sidebar-logo {
        display: none;
    }
    #dashboard-view {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2vh;
    }
    #dashboard-content {
        margin: 0;
        order: 1;
        height: 89vh;
        border-radius: 0;
        overflow-y: scroll;
    }
    #dashboard-sidebar {
        margin: 0;
        padding: 0 1rem;
        order: 2;
        /* position: fixed; */
        /* bottom: 0; */
        width: 100%;
    }

    #dashboard-sidebar {
        flex-direction: column;
        justify-content: flex-end;
        padding: 0;
    }

    #dashboard-sidebar-main ul {
        display: flex;
        /* flex-direction: row; */
    }

    #dashboard-sidebar-main ul p {
        display: none;
    }

    #dashboard-sidebar-main svg {
        margin: 1rem 0;
    }
}