#target-audience {
    padding-top: 4rem;
    margin-top: 0;

}

#target-audience h2 {
    margin-bottom: 0;
}

#target-audience .select-all {
    margin-bottom: 2rem;
}

#target-audience .content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

#target-audience .left-side {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* #target-audience .right-side {
    background-image: url("https://i.postimg.cc/TYvgVLTb/3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
} */

#target-audience .right-side {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

#target-audience swiper-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

/* swiper-container {
  width: 100% !important;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
} */

#target-audience .carousel-img-container {
    width: 90%;
    text-align: center;
}

#target-audience .carousel-img-container img {
    width: 100%;
    max-width: 100%;
}

.checklist-container .checklist {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
}

#target-audience h2 {
    display: block;
    background-color: transparent;
    font-size: 3rem;
    text-align: center;
    /* font-family: 'Inconsolata', monospace; */
    /* font-family: 'Helvetica-w01-light', sans-serif; */
    padding: 0;
}

#target-audience p {
    font-size: 1.1rem;
    margin-top: 0;
    line-height: 1.5;
}

#target-audience p:first-child {
    font-weight: 600;
}

#target-audience p:nth-child(2) {
    font-size: 1rem;
}

#target-audience button {
    border-radius: 0.5rem;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    margin-top: 2rem;
    transition: all 0.2s ease-out;
    box-shadow: -6px 14px 12px 2px rgba(90, 90, 90, 0);
    /* top: 0; */
}

#target-audience button:disabled {
    background-color: gray;
    filter: blur(1px);
    cursor: not-allowed;
    transform: translateY(0);
    /* top: 0; */
}

#target-audience button:not(:disabled) {
    /* position: relative; */
    transform: translateY(-3px);
    background-color: #feeb22;
    color: black;
    top: -3px;
    box-shadow: -6px 14px 12px 2px rgba(90, 90, 90, .12);
}

#target-audience swiper-slide {
    height: 100%;
    display: flex;
    align-items: center;
}

#target-audience swiper-slide:not(.swiper-slide-active) {
    opacity: 0 !important;
}

/* @media screen and (max-width: 1200px) {
    #target-audience .content-wrapper {
        grid-template-columns: 2fr 1fr;
        place-items: flex-start;
    }
    #target-audience swiper-container {
        display: none;
    }

    #target-audience .multiple-img-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        height: fit-content;
        gap: 1rem;
    }

    #target-audience .multiple-img-container img {
        height: 200px;
        width: 200px;
    }
} */

#target-audience-marquee img {
    padding: 0;
}

#target-audience swiper-container {
    display: none;
}

#target-audience-marquee {
    display: flex;
    transform: translateX(0);
}

@media screen and (max-width: 1350px) {
    #target-audience swiper-container {
        display: none;
    }

    #target-audience-marquee {
        display: flex;
    }

    #target-audience .content-wrapper {
        grid-template-columns: 2fr 1fr;
    }
}


@media screen and (max-width: 1024px) {
    #target-audience .content-wrapper {
        grid-template-columns: 1fr;
    }

    #target-audience swiper-container {
        display: block;
    }

    #target-audience-marquee {
        display: none;
    }

    /* #target-audience-marquee.marquee-wrapper.marquee--vertical .marquee {
        flex-direction: row;
        width:fit-content;
    }

    #target-audience-marquee .marquee__group {
        width: fit-content;
        min-width: fit-content;
    } */
}

@media screen and (max-width: 768px) {
    #target-audience {
        padding-left: 0.5rem;
        padding-right: 1.5rem;
    }

    #target-audience .content-wrapper {
        grid-template-columns: 1fr;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    #target-audience h2 {
        font-size: 2.5rem;
    }

    /* #target-audience .right-side {
        display: none;
    } */
}