.preset-lut-card {
    box-sizing: border-box;
    height: 300px;
    width: 250px;
    padding-bottom: 1rem;
    background-color: #160B03;
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    font-family: 'Helvetica Neue', sans-serif;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

/* .preset-lut-card:hover {
    scale: 1.05;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
} */

.preset-lut-card p {
    margin: 0;
}

.preset-lut-card p, .preset-lut-card svg, .preset-lut-card button {
    margin-left: 1rem;
}

.preset-lut-card svg {
    color: black;
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-sizing: content-box;
}

.preset-lut-card svg:hover {
    color: white;
    background-color: #e0e0e0;

}

.preset-lut-card-image {
    width: 100%;
    height: 65%;
    background-color: white;
}

.preset-lut-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.preset-lut-card button {
    width: fit-content;
    padding: 0.5rem 1rem;
    background-color: white;
    color: black;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
}

.preset-lut-card button:hover {
    background-color: #e0e0e0;
    color: white;
}