:host {
  --swiper-theme-color: #000;
}

body {
  box-sizing: border-box;
  /* margin: 4rem 5% 8rem 5%; */
  margin: 0;


  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Courier New', Courier, monospace;

  font-size: 16px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;700&display=swap');

@font-face {
  font-family: 'Foundation Sans';
  src: url(./fonts/FoundationSans-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Apple Garamond Light';
  src: url(./fonts/applegaramond-light.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica-W01-Light';
  src: url(./fonts/Helvetica\ W01\ Light.ttf) format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3 {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  /* font-family: 'Apple Garamond Light'; */
  margin: 0 0 1rem 0;
}

#root {
  --faq-item-bg-color: #F7F6F8;
}

#root>* {
  padding: 0 5%;
}

.darkgray-background {
  background-color: #484848;
  color: #fff;
}

.darkgray-background:hover {
  color: #000;
  /* background-color: #feeb22; */
}

.lightgray {
  color: #F2F1F2;
}

button:hover {
  cursor: pointer;
  background-color: #feeb22;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0 0.1rem 0 0.1rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

* {
  box-sizing: border-box;
}

.hero {
  position: relative;
  /* padding: 5% 0rem 2rem 8rem; */
  padding: 4rem 0 4rem 8rem;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 3fr;
  overflow: hidden;
  align-items: center;
}

.hero .video-container {
  position: relative;
  /* overflow: hidden; */
  width: 50vw;
  z-index: 4;
  display: none;
  height: 600px;
  filter: drop-shadow(18px 16px 30px #555);
}

.hero img {
  position: absolute;
  /* top: -100px; */
  right: 0;
  /* object-fit: cover; */
  /* object-position: center; */
  z-index: 10;
  /* filter: blur(10px); */
  /* transform: scale(1.1); */
  pointer-events: none;

}

.hero .film-image {
  height: 297px;
  top: -108px;
  right: -37px;
  z-index: 12;
}

.hero .skeleton-image {
  height: 150px;
  top: 50px;
  left: -100px;
}

.hero .filmbox-image {
  height: 745px;
  bottom: -500px;
  right: -367px;
  rotate: -90deg;
}

.hero .sourmo-image {
  height: 500px;
  bottom: -260px;
  left: -9px;
}

.hero .brainwave-image {
  top: -554px;
  right: -860px;
}

.hero .modern-image {
  height: 336px;
  right: -100px;
  top: 72px;
  z-index: 12;
}

.hero .twitter-image {
  height: 250px;
  bottom: -179px;
  right: 250px;
}

.hero .eventho-image {
  right: -120px;
  top: 293px;
}

.hero .text-image {
  height: 300px;
  bottom: -299px;
  right: 229px;
}

.hero .can-image {
  top: -91px;
  z-index: 1;
  right: 179px;
  height: 372px;
}

.hero .photo-image {
  width: 900px;
  z-index: 0;
  top: -232px;
  left: -50px;
}

.hero .welcome-image {
  width: 400px;
  left: -71px;
  top: -172px;
}

.hero .spongebob-image {
  width: 100px;
  bottom: -172px;
  left: 196px;
}

.hero .nature-image {
  right: -201px;
  top: 140px;
}

.hero .oatsman-image {
  left: -100px;
  bottom: -115px;
  width: 200px;
}

.hero .lines-image {
  left: -416px;
  bottom: -528px;
  z-index: 11;
}

.hero .oats-image {
  bottom: -461px;
  left: -228px;
}


iframe {
  border: none;
  /* Remove default iframe border */
  border-radius: 10px;
  /* Adjust the border radius as needed */
  overflow: hidden;
  /* Ensure the content stays within the rounded corners */
  display: block;
  /* Avoid any inline spacing issues */
  position: relative;
  z-index: 4;
}

.hero .video-container iframe {
  width: 900px;
  border-radius: 1rem;
}

.hero-title {
  font-size: 5rem;
  max-width: 13ch;
  line-height: 1.1;
  color: #343434;
  mix-blend-mode: color-burn;
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  /* position: relative; */
}

.hero-title-container {
  display: none;
  position: relative;
  z-index: 5;
  padding: 4rem;
}

.hero-title__overlay {
  position: absolute;
  font-size: 5rem;
  max-width: 13ch;
  line-height: 1.1;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  top: 0;
}

.hero-title[data-overlay] {
  color: #000;
  position: absolute;
  top: 0;
  font-size: 5rem;
  max-width: 13ch;
  line-height: 1.1;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  z-index: -2;
  mix-blend-mode: revert;
  display: none;
}

.hero .container {
  --max-width: 50rem;
  width: min(100% - 2rem, var(--max-width));
  margin-left: 15%;
}

.hero-video {
  position: relative;
  width: 900px;
  z-index: 4;
}

.gradient-container {
  display: none;
}

#gradient-canvas {

  /* --gradient-color-1: #6ec3f4;
  --gradient-color-2: #3a3aff;
  --gradient-color-3: #ff61ab;
  --gradient-color-4: #E63946; */
  --gradient-color-1: #faaf08;
  --gradient-color-2: #fa812f;
  --gradient-color-3: #fa4032;
  --gradient-color-4: #e104ff;
  /* --gradient-color-4: #fdd475; */
  /* clip-path: polygon(0 0, 100% 0, 100% 30%, 0% 100%); */
  width: 100vw;
  height: 100vh;
  position: absolute;
  inset: 0;
  z-index: -1;
}

#gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 98%);
  pointer-events: none;
  /* Allow interactions with elements beneath the overlay */
}

.mobile {
  display: none;
}

@media screen and (max-width: 1600px) {
  .hero {
    padding-left: 4rem;
  }

}

/* @media screen and (max-width: 1440px) {
  .hero {
    padding-left: 8rem;
  }
} */

@media screen and (orientation: landscape) and (max-height: 1000px) {
  .hero {
    padding-top: 4rem;
  }
}

@media screen and (orientation: portrait) {
  .hero {
    grid-template-columns: 1fr;
    padding-left: 0;
    place-items: center;
  }

  .hero .video-container {
    width: fit-content;
    height: fit-content;
    align-self: flex-start;
  }

  /* .hero .video-container {
  } */

  .hero .film-image {
    height: 334px;
    top: -141px;
    right: -222px;
    z-index: 12;
  }

  .hero .text-image {
    height: 244px;
    bottom: -248px;
    right: 18px;
}

  .hero .photo-image {
    width: 637px;
    z-index: 0;
    top: -159px;
    left: -39px;
}

.hero .twitter-image {
  height: 206px;
  bottom: -144px;
  right: 24px;
}

.hero .eventho-image {
  left: -194px;
  top: 282px;
}

  .hero .spongebob-image {
    width: 100px;
    bottom: -155px;
    left: 115px;
}

  .hero .can-image {
    top: -148px;
    z-index: 1;
    right: 5px;
  }

  .hero .nature-image {
    right: -345px;
    top: 141px;
}

  .hero .modern-image {
    height: 336px;
    right: -229px;
    top: 72px;
    z-index: 12;
  }

  .hero .lines-image {
    left: -416px;
    bottom: -463px;
    z-index: 11;
}

  .hero .oatsman-image {
    left: -86px;
    bottom: 24px;
    width: 161px;
  }

  .hero .sourmo-image {
    height: 381px;
    bottom: -203px;
    left: -42px;
}

/* .hero .filmbox-image {
  height: 944px;
  bottom: -571px;
  left: 0px;
} */

.hero .filmbox-image {
  height: 618px;
  bottom: -400px;
  right: -438px;
}

.hero .brainwave-image {
  top: -554px;
  right: -848px;
}

  .hero .oats-image {
    bottom: -392px;
    left: -228px;
}

  .hero-title-container {
    padding-left: 8rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    place-self: flex-start;
  }

  .hero-video {
    width: 550px;
  }
}

@media screen and (orientation: portrait) and (max-width: 768px) {
  .hero {
    width: 100vw;
    gap: 1rem;
  }

  .hero .video-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .hero-video {
    width: 80%;
  }

  .hero .photo-image {
    width: 579px;
    z-index: 0;
    top: -56px;
    left: 37px;
  }

  .hero .film-image {
    height: 268px;
    top: -31px;
    right: -33px;
    z-index: 12;
  }

  .hero .sourmo-image {
    height: 369px;
    bottom: -99px;
    left: 52px;
  }

  .hero .filmbox-image {
    height: 938px;
    bottom: -485px;
    right: -414px;
    display: none;
  }

  .hero .twitter-image {
    height: 192px;
    bottom: -60px;
    right: 252px;
  }

  .hero .skeleton-image {
    height: 108px;
    top: 146px;
    left: -7px;
  }

  .hero .oatsman-image {
    left: -9px;
    bottom: 24px;
    width: 161px;
  }

  .hero .can-image {
    top: -16px;
    z-index: 1;
    right: 136px;
    height: 300px;
  }

  .hero .welcome-image {
    width: 333px;
    left: -41px;
    top: -79px;
  }

  .hero .brainwave-image {
    height: 700px;
    top: -270px;
    right: -459px;
  }

  .hero-title-container {
    padding: 0;
    width: 100%;
  }

  .hero-title-container div {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

}

@media screen and (orientation: landscape) and (max-width: 1366px) {
  .hero-video {
    width: 700px;
  }

  .hero {
    padding-left: 4rem;
  }

  .hero-title-container {
    padding: 0;
  }

  .hero .brainwave-image {
    top: -500px;
    right: -792px;
  }

  .hero .modern-image {
    height: 336px;
    right: -183px;
    top: 72px;
    z-index: 12;
  }

  .hero .filmbox-image {
    height: 700px;
    bottom: -400px;
    right: -404px;
  }

  .hero .twitter-image {
    height: 182px;
    bottom: -86px;
    right: 133px;
  }

  .hero .skeleton-image {
    height: 125px;
    top: 50px;
    left: -71px;
  }

  .hero .nature-image {
    top: 136px;
    right: -188px;
  }

  .hero .oatsman-image {
    left: -86px;
    bottom: 24px;
    width: 165px;
  }

  .hero .welcome-image {
    width: 305px;
    left: -48px;
    top: -148px;
  }

  .hero .film-image {
    height: 300px;
    top: -138px;
    right: -50px;
    z-index: 12;
  }

  .hero .text-image {
    height: 300px;
    bottom: -234px;
    right: 72px;
  }

  .hero .spongebob-image {
    width: 100px;
    bottom: -109px;
    left: 196px;
  }

  .hero .lines-image {
    left: -413px;
    bottom: -398px;
    z-index: 11;
  }

  .hero .oats-image {
    bottom: -326px;
    left: -228px;
  }

  .hero .sourmo-image {
    height: 500px;
    bottom: -185px;
    left: -9px;
  }
}

@media screen and (max-width: 768px) and (max-height: 896px) {
  .hero video {
    height: fit-content;
  }

  .hero .can-image {
    top: -52px;
    z-index: 1;
    right: 92px;
    height: 175px;
}

  .hero .photo-image {
    display: none;
  }

  /* .hero img {
    display: none;
  } */
  .hero .brainwave-image {
    height: 449px;
    top: -274px;
    right: -325px;
}

.hero .filmbox-image {
  height: 562px;
  bottom: -351px;
  right: -285px;
}
.hero .film-image {
  height: 151px;
  top: 122px;
  right: -33px;
  z-index: 12;
}

.hero .skeleton-image {
  height: 72px;
  top: 35px;
  left: -7px;
}

.hero .nature-image {
  display: none;
}

.hero .text-image {
  display: none;
}

.hero .eventho-image {
  display: none;
}

.hero .film-image {
  height: 151px;
  top: -49px;
  right: -33px;
  z-index: 12;
}

.hero .oatsman-image {
  left: -9px;
  bottom: -23px;
  width: 95px;
}

.hero .sourmo-image {
  height: 250px;
  bottom: -132px;
  left: 145px;
}

.hero .text-image {
  height: 131px;
  bottom: 39px;
  right: 124px;
}

.hero .modern-image {
  height: 227px;
  right: -87px;
  top: -2px;
  z-index: 12;
}

.hero .spongebob-image {
  display: none;
  width: 55px;
  bottom: 97px;
  left: 98px;
}

.hero .twitter-image {
  display: none;
  height: 114px;
  bottom: 93px;
  right: 135px;
}

.hero .lines-image {
  left: -140px;
  bottom: -197px;
  height: 500px;
  z-index: 11;
}

.hero .photo-image {
  width: 313px;
  z-index: 0;
  top: 101px;
  left: 23px;
}

.hero .filmbox-image {
  height: 602px;
  bottom: -206px;
  right: -265px;
}

.hero .welcome-image {
  width: 191px;
  left: -17px;
  top: -84px;
}
}

/* mobile viewport */
@media screen and (max-width: 768px) {

  .hero .video-container {
    height: fit-content;
  }

  .hero .hero-video {
    height: 60%;
  }

  #root>* {
    padding: 0;
  }

  .mobile {
    display: block;
  }

  .hero {
    padding: 0;
  }

  .hero-title-container {
    top: 8rem;
    text-align: center;
  }

  .hero .hero-title {
    font-size: 3rem;
    width: 100%;
    display: inline-block;
  }

  #gradient-canvas {
    height: 100vh;
  }

  .hero-title[data-overlay] {
    display: none;
  }

  .hero {
    grid-template-columns: 1fr;
  }
}