.presets-hp {
    margin: 8rem 0;
    /* font-family: 'Apple Garamond Light'; */
}

.presets-hp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.shop-presets-btn {
    font-size: 1.25rem;
}

/* Presets v2 */
.presets-hp .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    gap: 3rem 3rem;
}

.presets-hp .grid-text {
    grid-column: span 2;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.presets-hp .grid-item {
    height: 400px;
    /* padding: 1rem; */
}

@media screen and (max-width: 768px) {

    .presets-hp {
        margin: 4rem 0 8rem 0;
    }

    .presets-hp .grid-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem 1rem;
    }

    .presets-hp .grid-item {
        height: 300px;
    }
    
}

