#money-back-guarantee {
    width: 100vw;
    transform: translateX(-5%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding: 4rem 0;
    background: rgb(247, 157, 42);
    background: linear-gradient(63deg, rgba(247, 157, 42, 1) 28%, rgba(225, 4, 255, 1) 100%);
    margin-top: 0;
}

.money-back-guarantee-container {
    width: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 5%;
    border-radius: 1rem;
    color: white;
}

#money-back-guarantee h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    padding: 0;
    background-color: transparent;
}

#money-back-guarantee p {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
    padding: 0;
}

#money-back-guarantee hr {
    display: inline-block;
    margin: 1rem auto 1rem 0;
    width: 13ch;
    border: 2px solid white;
}

#money-back-guarantee button {
    display: inline-block;
    width: fit-content;
    text-decoration: underline;
}

#money-back-guarantee button:hover {
    background-color: transparent;
    color: #999;
}

#money_back_dialog {
    width: 55%;
    padding: 0;
    /* border-color: var(--faq-item-bg-color); */
    border-color: #999;
}

#money_back_dialog svg {
    font-size: 1.5rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
}

#money_back_dialog svg:hover {
    cursor: pointer;
    color: #999;
}

#money_back_dialog .dialog__content {
    padding: 2rem;
}

#money_back_dialog .more_info{
    margin-top: 2rem;
    font-size: 1rem;
}

@media screen and (max-width: 1366px) {
    #money-back-guarantee {
        padding: 2rem;
        height: fit-content;
    }

    .money-back-guarantee-container {
        height: fit-content;
        width: 60%;
        padding: 2rem;
    }
    
}

@media screen and (max-width: 768px) {

    #money-back-guarantee {
        transform: translateX(-1rem);
    }

    .money-back-guarantee-container {
        width: 100%;
        padding: 1rem;
    }

    #money_back_dialog {
        width: 100%;
    }

    #money_back_dialog .dialog__content {
        padding: 1rem;
    }
    
}