.select-container {
    position: relative;
    width: 400px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.select-container .select {
    position: relative;
    /* background: #0f0e11; */
    background-color: white;
    height: 50px;
}

.select-container .select::after {
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    right: 15px;
    transform: translateY(-50%) rotate(45deg);
    border-bottom: 2px solid #0f0e11;
    border-right: 2px solid #0f0e11;
    cursor: pointer;
    transition: border-color 0.4s;
}

.select-container.active .select::after {
    border: none;
    border-left: 2px solid white;
    border-top: 2px solid white;
}
.select-container .select input {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    background: none;
    outline: none;
    border: none;
    font-size: 1rem;
    color: black;
    cursor: pointer;
}
.select-container .option-container {
    position: relative;
    background: #6e6477;
    max-height: 0;
    overflow-y: scroll;
    transition: 0.4s;
}

.select-container.active .option-container {
    max-height: 100vh;
}

.select-container .option-container::-webkit-scrollbar {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    width: 10px;
}

.select-container .option-container::-webkit-scrollbar-thumb {
    background: #0f0e11;
}

.select-container .option-container .option {
    position: relative;
    padding-left: 15px;
    height: 60px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.2s;
}

.select-container .option-container .option.selected {
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
}

.select-container .option-container .option:hover {
    background: rgba(0, 0, 0, 0.2);
    padding-left: 20px;
}

.select-container .option-container .option label {
    font-size: 0.8rem;
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .select-container {
        width: 100%;
    }
}
