.module-preview {
    box-sizing: border-box;
    position: relative;
    display: grid;
    grid-template-columns: 240px 3fr;
    width: 100%;
    min-height: 150px;
    max-height: 1000px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 1rem;
    grid-gap: 1rem;
    background-color: white;
    margin-bottom: 1.5rem;
}

.module-preview:hover {
    cursor: pointer;
}

.module-preview.disabled:hover .purchase-note {
    opacity: 1;
}

.module-preview.disabled svg.lock-closed {
    display: block;
}

.module-preview.disabled:hover .purchase-note .lock-closed {
    display: none;
}

.module-preview.disabled:hover svg.lock-open {
    display: block;
    opacity: 1;
}

.module-preview.disabled .lock-open {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.module-preview.disabled .module-preview-img-container, .module-preview.disabled .module-preview-info {
    opacity: 0.5;
    filter: blur(1px);
}

/* .module-preview.disabled {
    opacity: 0.5;
    filter: blur(1px);
} */

.module-preview svg {
    display: none;
}

.module-preview .purchase-note {
    display: none;
}

.module-preview.disabled .purchase-note {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-family: 'Inconsolata', monospace;
    font-size: 1.25rem;
    transition: all 0.3s ease-out;
}

.module-preview.disabled:hover .purchase-note {
    background-color: #f2f1f2;
}

.module-preview.disabled .purchase-text {
    transition: opacity 0.3s ease-out;
    opacity: 0;
}

.module-preview.disabled:hover .purchase-text {
    opacity: 1;
}

.module-preview.active {
    background-color: #f2f1f2;
}

.module-preview-info {
    width: 100%;
}

.module-preview-info h3 span:nth-of-type(2) {
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 400;
}

.module-preview-img-container {
    width: 100%;
    height: 100%;
    background-color: #f2f1f2;
    border-radius: 10px;
}

@media screen and (max-width: 1500px) {
    .module-preview {
        grid-template-columns: 1fr;
    }

    .module-preview-img-container {
        width: 240px;
        height: 135px;
    }
}


/* @media screen and (max-width: 1200px) {
    .module-preview-img-container {
        display: none;
    }

    .module-preview {
        grid-template-columns: 1fr;
    }
} */

@media screen and (max-width: 768px) {
    .module-preview-img-container {
        display: block;
    }

    .module-preview {
        grid-template-columns: 1fr;
    }

    .module-preview {
        align-items: center;
        min-height: 140px;
        height: fit-content;
    }

    .module-preview h3 {
        margin: 0;
    }

    .module-preview-info p {
        display: none;
    }
    
}