/* .swiper {
  width: 100%;
  height: 300px;
} */
/* 
swiper-container {
  margin: 8rem 0;
  width: 100%;
  height: 400px;
} */

swiper-slide {
  display: flex;
  justify-content: center;
}

.testimonials-container h2 {
  margin: 0 0 8rem 0;
}