.homepage {
    /* font-family: 'Inconsolata', monospace; */
    font-family: 'Helvetica-w01-light', sans-serif;
    /* font-family: 'Foundation Sans', sans-serif; */

}

.homepage h1, h2, h3, h4 {
    font-family: 'Foundation Sans', sans-serif;
}

.homepage .page-section {
    margin: 4rem 0;
}

.homepage h2 {
    margin-bottom: 4rem;
    /* font-family: 'Inconsolata', monospace; */
    /* font-family: 'Helvetica-w01-light', sans-serif; */

    padding: 0;
}

#homepage-nav {
    vertical-align: baseline;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100vw;
    transform: translateX(-5%);
    font-family: 'Foundation Sans', sans-serif;
    background-color: var(--faq-item-bg-color);
}

#homepage-nav ul {
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: space-between;
    margin: 0;
}

#homepage-nav ul li:not(:first-child) {
    border-left: none;
}

/* #homepage-nav ul li:not(:last-child) {
    border-right: none;
} */

#homepage-nav ul li {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    border: 1px solid black;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-out;
}

#homepage-nav ul li:hover {
    background-color: #feeb22;
    transform: translateY(-3px);
}

/* #homepage-nav ul li a {
} */


/* .homepage {
    font-family: 'Courier New', Courier, monospace;
} */

.homepage .testimonials-container,
.best-sellers,
.presets-hp,
.masterclass-hp {
    display: none;
}

.dotted-line {
    border-bottom: 3px solid transparent; /* Set the border to a solid color to fallback on non-supporting browsers */
    border-image: repeating-linear-gradient(45deg, var(--faq-item-bg-color), var(--faq-item-bg-color) 4px, transparent 4px, transparent 8px);
    border-image-slice: 1;

    width: 100%;
    margin-bottom: 2rem;
}

.homepage .pop-up {
    position: fixed;
    width: 600px;
    height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.homepage .pop-up img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.homepage .pop-up svg {
    position: absolute;
    top: 4rem;
    right: 4rem;
    width: 50px;
    height: 50px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2000;
}

.homepage .pop-up svg:hover {
    color: var(--faq-item-bg-color);
    background-color: black;
}

/* mobile viewport */
@media screen and (max-width: 768px) {

    #root>* {
        padding: 0 1rem;
    }

    .homepage {
        position: relative;
    }

    .homepage .page-section {
        margin: 0;
    }

    #homepage-nav {
        display: none;
    }
    #homepage-nav ul {
        flex-direction: column;
    }

    #homepage-nav ul li:not(:first-child) {
        border-left: 1px solid black;
    }

    #homepage-nav ul li {
        height: 50px;
    }
}

@media screen and (max-width: 500px) {
    .homepage .pop-up {
        width: 90%;
        height: auto;
    }

    .homepage .pop-up img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .homepage .pop-up svg {
        top: 2rem;
        right: 2rem;
    }
    
}