#what-we-offer {
    width: 100vw;
    transform: translateX(-5%);
    background-color: var(--faq-item-bg-color);
    padding: 4rem 2rem;
}

#what-we-offer .offer-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

#what-we-offer h2 {
    font-size: 3rem;
    display: block;
    background-color: transparent;
    text-align: center;
}

#what-we-offer .offer {
    height: 650px;
    width: 450px;
    transition: all 1s ease-out;
    position: relative;
}

#what-we-offer .image-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    border-radius: 1rem;
    overflow: hidden;
}

#what-we-offer .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(2px) brightness(0.6);
    transition: all 1s ease-out;
}

#what-we-offer .offer:hover img {
    filter: blur(0) brightness(0.6);
    transform: scale(1.1);
}

#what-we-offer .offer .text-container p {
    opacity: 0;
    transition: all 1s ease-out;
    transform: translateY(1rem);
    position: absolute;
    bottom: 0;
    padding: 2rem;
}

#what-we-offer .offer:hover .text-container p {
    opacity: 1;
    transform: translateY(0);
}

#what-we-offer .offer .text-container .title-icon-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 1s ease-out;
}

#what-we-offer .offer:hover .text-container .title-icon-container {
    top: 4rem;
    transform: translateY(0);
}

#what-we-offer .text-container {
    color: white;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 10;
    padding: 4rem 2rem;
    height: 100%;
}

#what-we-offer .text-container h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    padding: 0 2rem;
}

#what-we-offer .text-container p {
    line-height: 1.5;
}

#what-we-offer .text-container p,
#what-we-offer .text-container svg {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

#what-we-offer .text-container svg {
    font-size: 2.5rem;
}

#what-we-offer .offer .text-container .title-icon-container {
    opacity: 1;
}

#what-we-offer .offer:hover .text-container .title-icon-container {
    opacity: 0;
}

/* #what-we-offer .offer-1:hover, #what-we-offer .offer-2:hover, #what-we-offer .offer-3:hover  {
    background-image: url("https://images.squarespace-cdn.com/content/v1/61a46cc601b4c521a42206d3/1645247526671-F8QXSKJO9WXL86EJ8CC6/DSC07768-1-1.jpeg?format=750w");
    background-size: cover;
    scale: 1.1;
} */

/* #what-we-offer .offer-2 {
    background-image: url("https://images.squarespace-cdn.com/content/v1/61a46cc601b4c521a42206d3/1645247526671-F8QXSKJO9WXL86EJ8CC6/DSC07768-1-1.jpeg?format=750w");
}
#what-we-offer .offer-3 {
    background-image: url("https://images.squarespace-cdn.com/content/v1/61a46cc601b4c521a42206d3/1645247526671-F8QXSKJO9WXL86EJ8CC6/DSC07768-1-1.jpeg?format=750w");
} */

@media screen and (orientation: portrait) {
    #what-we-offer .offer-container {
        flex-wrap: wrap;
        gap: 2rem;
    }
}

/* @media screen and (orientation: portrait) and (max-width: 1025px) {
    #what-we-offer .offer {
        width: 45vw;
        height: 50vh;
    }
} */

@media screen and (max-width: 1366px) {
    #what-we-offer .offer .text-container p {
        font-size: 1.25rem;
        padding: 1rem;
    }

    /* #what-we-offer .offer {
        width: 30vw;
    } */
}

@media screen and (max-width: 1150px) {
    /* #what-we-offer .offer {
        width: 45vw;
    } */

    #what-we-offer .offer-container {
        flex-wrap: wrap;
        gap: 2rem;
    }
}

@media screen and (max-width: 1000px) {
    #what-we-offer .offer-container {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    #what-we-offer {
        padding: 4rem 1rem;
        width: 100vw;
        transform: translateX(-1rem);
    }

    #what-we-offer h2 {
        font-size: 2rem;
    }

    #what-we-offer .text-container h3 {
        font-size: 1.5rem;
    }

    #what-we-offer .offer .text-container p {
        font-size: 1rem;
    }

    #what-we-offer .offer {
        height: 80vh;
        width: 100%;
    }

    #what-we-offer .offer-container {
        flex-direction: column;
        gap: 2rem;
    }

    #what-we-offer .offer .text-container .title-icon-container {
        opacity: 1;
    }

    #what-we-offer .offer:hover .text-container .title-icon-container {
        opacity: 0;
    }

}