/* .header  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-self: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-family: 'Foundation Sans';
} */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-self: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
    /* font-family: 'Foundation Sans'; */
    font-family: 'Helvetica-W01-Light', sans-serif;
    position: absolute;
    top: 0;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    z-index: 10;
    /* margin: auto; */
}

.header .user-directory {
    display: flex;
    justify-content: right;
    align-items: center;
    font-family: 'Foundation Sans', sans-serif;
    text-align: left;
}

.header .user-directory svg {
    transition: color 0.75s ease-out;
    font-size: 2rem;
}

.header .user-directory .icon-wrapper {
    transition: all 0.3s ease-in-out;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
}

.header .user-directory .icon-wrapper:hover {
    cursor: pointer;
}

.header .logo, .header .user-directory {
    z-index: 2;
}

.header .user-directory > *:not(:last-child) {
    margin-right: 2rem;
}

.header .logo {
    height: 3rem;
}

.header .logo svg {
    height: 100%;
    image-rendering: pixelated;
    transition: fill 0.75s ease-out;
    cursor: pointer;
}

.header .logo img {
    height: 100%;
}

.header .logo img:hover {
    cursor: pointer;
}

.header .logo a {
    height: fit-content;
}

.header .logo a:hover {
    background-color: transparent;
}

.header > *{
    width: 25%;
} 

/* .header > div:nth-child(2){
    text-align: center;
} */

.header .user-directory {
    text-align: end;
}

.header span {
    padding: 0.25rem 0.1rem;
    font-size: 1.25rem;
}

.header .user-directory > * {
    background-color: #282c34;
    transition: background-color 0.75s ease-out;
}

.user-directory a {
    color: white;
}

.user-directory a:hover {
    background-color: transparent;
}

.header span:hover {
    cursor: pointer;
}

/* .header span:hover a {
    color: black;
} */

.user-directory span{
    margin: 0 0 0 0rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    color: white;
    /* background-color: rgba(202, 202, 202, 0.4); */
    transition: color 0.75s ease-out;
}

.user-directory span:last-child {
    margin: 0 0 0 3rem;
}

/* Login mockup */
#mock-log {
    display: none;
}

@media screen and (max-width: 1024px) {
    .header .header-logout-text, .header .header-get-started-text {
        display: none;
    }
}


/* mobile viewport */
@media screen and (max-width: 768px) {

    .user-directory span:first-child, .user-directory span:nth-child(2) {
        display: none;
    }

    .header {
        margin-top: 3rem;
    }

    .header .logo {
        height: 2rem;
    }

    .header .user-directory {
        justify-content: center;
    }

    .hamburger-wrapper {
        top: 2rem;
    }

    #hamburger-menu.is-active {
        height: 100vh;
    }
}