#root #digital-downloads {
    margin: 15rem 25% 15rem 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Inconsolata', monospace;
    gap: 1rem;
}

#digital-downloads h1 {
    font-size: 2rem;
    margin-bottom: 3rem;
}

#digital-downloads h2 {
    background-color: transparent;
    padding: 0;
    margin: 0;
    font-size: 1.25rem;
}

#digital-downloads .download-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #666;
    padding: 2rem 1rem;
    border-radius: 0.5rem;
}

#digital-downloads .download-container a {
    text-decoration: none;
    color: black;
    background-color: #f5f5f5;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
}

#digital-downloads .download-container a:hover {
    background-color: #e5e5e5;
}