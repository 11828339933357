.footer {
    font-family: 'Courier New', Courier, monospace ;
    font-size: 0.75rem;
}

.footer-logo img {
    height: 4rem;
}

.footer input {
    border: none;
    outline: none;
    background: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
}

.footer .newsletter-form span {
    margin: 0 1rem 0 0;
}

/* mobile viewport */
@media screen and (max-width: 768px) {
    body {
        padding: 0 0 0 0;
    }
    #root .footer {
        /* position: relative;
        top: 4rem; */
        padding: 0rem 1rem;
        margin-bottom: 4rem;
    }
}