#hamburger-menu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 10rem;
    padding-bottom: 2rem;
    position: absolute;
    background-color: white;
    left: 0;
    height: fit-content;
    top: -100vh;
    width: 100vw;
    z-index: 5;
    transition: top 0.75s ease-in-out;
    justify-items: center;
    font-family: 'Inconsolata', monospace;
}

#hamburger-menu.is-active {
    top: 0;
}


#hamburger-menu #socials-list li:hover {
    background-color: #feeb22;
}

#hamburger-menu h3 {
    font-size: 2rem;
    font-family: 'Inconsolata', monospace;
}

#hamburger-menu a:hover {
    background-color: #feeb22;
    display: inline-block;
    width: fit-content;
}

#hamburger-menu a.disabled {
    color: #999;
    pointer-events: none;
    cursor: default;
}

#hamburger-menu a.disabled span {
    font-style: italic;
    font-size: 0.85rem;
} 

#hamburger-menu hr {
    width: 100%;
    justify-self: left;
    display: inline-block;
    background-color: black;
    height: 2px;
}

#hamburger-menu > div {
    width: 50%;
}

#hamburger-menu .link1 {
    display: block;
    text-decoration: none;
    color: black;
    font-size: 1.25rem;
    text-align: left;
}

.ham-menu-links {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.connect-category-links button {
    display: none;
}

#socials-list {
    display: flex;
    list-style: none;
    padding: 0;
    gap: 1rem;
}

#socials-list li {
    cursor: pointer;
}

#socials-list svg {
    font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
    #hamburger-menu {
        height: 100vh;
        top: -100vh;
        grid-template-columns: 1fr;
        padding: 8rem 0;
        row-gap: 1.5rem;
        overflow-y: scroll;
    }

    #hamburger-menu h3 {
        font-size: 1.25rem;
        margin-top: 2rem;
    }

    #hamburger-menu > div {
        width: 75%;
    }
}
