:root {
    --color-text: navy;
    --color-bg: papayawhip;
    --color-bg-accent: #ecdcc0;
    --size: clamp(10rem, 1rem + 40vmin, 10rem);
    --gap: calc(var(--size) / 14);
    --duration: 120s;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));
}

.marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    mask-image: linear-gradient(var(--mask-direction, to right),
            hsl(0 0% 0% / 0),
            hsl(0 0% 0% / 1) 20%,
            hsl(0 0% 0% / 1) 80%,
            hsl(0 0% 0% / 0));
}

.marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;
}

.marquee-wrapper {
    display: flex;
    flex-direction: column;
    /* gap: var(--gap); */
    margin: auto;
    max-width: 100vw;
    width: 100vw;
    margin-bottom: 2rem;
    transform: translateX(-5%);
}

.marquee-wrapper.mobile {
    display: none;
}

.marquee-wrapper.marquee--vertical {
    width: 100%;
    height: 100%;
}

.marquee-wrapper.marquee--vertical .marquee {
    height: 100%;
    flex-direction: column;
}

.marquee--vertical,
.marquee--vertical .marquee__group {
    flex-direction: column;
}

.marquee--vertical .marquee__group {
    animation-name: scroll-y;
}

.marquee--vertical {
    --mask-direction: to bottom;
}

@keyframes scroll-y {
    from {
        transform: translateY(var(--scroll-start));
    }

    to {
        transform: translateY(var(--scroll-end));
    }
}

.homepage .marquee-wrapper h2 {
    background-color: transparent;
    margin: 0;
    text-align: center;
    font-size: 1rem;
}

.marquee img {
    display: grid;
    place-items: center;
    width: var(--size);
    padding: calc(var(--size) / 10);
}

@keyframes scroll-x {
    from {
        transform: translateX(var(--scroll-start));
    }

    to {
        transform: translateX(var(--scroll-end));
    }
}

@media screen and (max-width: 768px) {
    .marquee__group {
        gap: 0;
    }

    /* #logo-marquee {
        margin-bottom: 0;
    } */
}