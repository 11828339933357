.testimonial-item {
    width: 50%;
    text-align: center;
    background-color: #F2F1F2;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testimonial-item p:first-child {
    margin-bottom: 4rem;
}

.testimonial-item p:last-child {
    width: 75%;
    margin-top: 4rem;
    font-family: helvetica;
    font-style: italic;
    line-height: 2rem;
}

.testimonial-item svg {
    font-size: 2rem;
}