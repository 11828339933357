.grid-two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    row-gap: 2rem;
    column-gap: 2rem;
}

.masterclass-desc {
    line-height: 1.5rem;
}

.masterclass-desc .img-container {
    width: 100%;
    text-align: center;
}

.masterclass-desc .img-container img {
    width: 100%;
    max-width: 100%;
}

.masterclass-desc .masterclass-desc-header .img-container {
    width: 80%;
    max-width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.masterclass-desc a {
    text-decoration: none;
    color: black;
}
/* 
.react-player {
    position: absolute;
    top: 0;
    left: 0;
  } */

.masterclass-desc .img-container {
    width: 80%;
    max-width: 80%;
}

.masterclass-desc h1, .masterclass-desc h2 {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
}

.masterclass-desc h2 {
    background-color: transparent;
    padding: 0;
}

.masterclass-what-we-offer {
    text-align: center;
}

.masterclass-what-we-offer ul {
    text-align: left;
}

.masterclass-desc-content {
    background-color: #f2f1f2;
    padding: 3rem 2rem;
}

.masterclass-desc-content section {
    margin: 4rem 0;
}

hr {
    width: 25%;
}

.masterclass-break-it-down > h2 {
    grid-column: span 2;
}

.masterclass-desc-content ul {
    padding: 0;
}

.module-listings ol > li {
    margin-bottom: 1rem;
}

.module-listings ol p {
    font-weight: bold;
}


.masterclass-who-for {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    text-align: center;
    row-gap: 8rem;
}

.masterclass-who-for > p {
    width: 75%;
}

.masterclass-who-for > h2 {
    grid-column: span 3;
}

.masterclass-who-for > button {
    grid-column: span 3;
}
@media screen and (max-width: 768px) {
    .masterclass-desc-content {
        padding: 2rem 0;
        background-color: transparent;
    }

    .masterclass-desc-content .grid {
        row-gap: 4rem;
    }

    .masterclass-desc-content .masterclass-desc-header {
        margin-top: 8rem;
    }

    .masterclass-desc-content section {
        margin: 4rem 0;
    }

    .masterclass-desc .back-to-btn {
        display: none;
    }

    .grid-two-columns {
        grid-template-columns: 1fr;
    }

    .masterclass-desc .masterclass-desc-header .img-container {
        width: 100%;
        max-width: 100%;
    }

    .masterclass-desc .react-player {
        height: 250px !important;
    }

    .masterclass-what-we-offer .section-text:nth-child(2) {
        order: 1;
    }

    .masterclass-what-we-offer .section-img:nth-child(2) {
        order: 2;
    }

    .masterclass-break-it-down>h2 {
        grid-column: span 1;
        order: 1;
    }

    .masterclass-break-it-down .module-listings:first-of-type {
        order: 2;
    }

    section.masterclass-break-it-down .module-img-container:nth-child(3) {
        order: 3;
    }

    .masterclass-break-it-down .module-listings:nth-child(5) {
        order: 4;
    }

    section.masterclass-break-it-down .module-img-container:nth-child(4) {
        order: 5;
    }

    .masterclass-break-it-down .module-listings:nth-child(6) {
        order: 6;
    }

    section.masterclass-break-it-down .module-img-container:nth-child(7) {
        order: 7;
    }

    .masterclass-break-it-down .module-listings:nth-child(9) {
        order: 8;
    }

    section.masterclass-break-it-down .module-img-container:nth-child(8) {
        order: 9;
    }

    .masterclass-break-it-down .module-listings:nth-child(10) {
        order: 10;
    }

    section.masterclass-break-it-down .module-img-container:nth-child(11) {
        order: 11;
    }

    .masterclass-break-it-down .module-listings:nth-child(13) {
        order: 12;
    }

    section.masterclass-break-it-down .module-img-container:nth-child(12) {
        order: 13;
    }

    .masterclass-who-for {
        grid-template-columns: 1fr;
        row-gap: 2rem;
    }

    .masterclass-who-for > h2 {
        grid-column: span 1;
    }

    .masterclass-who-for > button {
        grid-column: span 1;
        margin-top: 4rem;
    }
}
