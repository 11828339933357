.cart-item {
    display: flex;
    justify-content: space-between;
}

.cart-item.remove {
    display: none;
}

.cart-item .image-wrapper {
    width: 150px;
    height: 150px;
    margin-right: 1rem;
}

.cart-item .image-wrapper img {
    object-fit: contain;
    width: 150px;
    height: 150px;
}

.cart-item .item-details .product-number {
    color: #999;
}

.cart-item .image-and-details {
    display: flex;
    align-items: center;
}

.cart-item .remove-button svg {
    padding: 1rem;
    cursor: pointer;
    box-sizing: content-box;
}

.cart-item .remove-button svg:hover {
    color: #999;
}