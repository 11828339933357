.masterclass-hp .img-container {
    width: 100%;
    height: 700px;
}

.masterclass-hp .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.masterclass-hp .img-container {
    margin-bottom: 4rem; 
}

.masterclass-hp p {
    width: 50%;
}

.masterclass-hp button {
    font-size: 1.25rem;
}
.masterclass-hp a {
    background-color: #484848;
    color: #fff;
    font-size: 1.25rem;
}

.masterclass-hp a:hover {
    background-color: #feeb22;
    color: #000;
}

@media screen and (max-width: 768px) {
    .masterclass-hp p {
        width: 80%;
    }

    .masterclass-hp .img-container {
        height: 500px;
    }
}