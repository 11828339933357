.hamburger .line {
    width: 50px;
    height: 3px;
    background-color: black;
    display: block;
    margin: 8px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 0;
}

.hamburger:hover {
    cursor: pointer;
}

#hamburger-6.is-active {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transition-delay: 0.6s;
    -o-transition-delay: 0.6s;
    transition-delay: 0.6s;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#hamburger-6.is-active .line:nth-child(2) {
    width: 0px;
}

#hamburger-6.is-active .line:nth-child(1),
#hamburger-6.is-active .line:nth-child(3) {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
    width: 30px;
}

#hamburger-6.is-active .line:nth-child(1) {
    -webkit-transform: translateY(13px);
    -ms-transform: translateY(13px);
    -o-transform: translateY(13px);
    transform: translateY(13px);
}

#hamburger-6.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-9px) rotate(90deg);
    -ms-transform: translateY(-9px) rotate(90deg);
    -o-transform: translateY(-9px) rotate(90deg);
    transform: translateY(-9px) rotate(90deg);
}