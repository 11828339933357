.sidebar {
    position: fixed;
    top: 0;
    left: -38%;
    width: 33%;
    height: 100vh;
    background-color: #f2f1f2;
    transition: left 0.3s ease-in-out;
    z-index: 110;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
}

.show-overlay {
    opacity: 1;
    visibility: visible;
}

.sidebar h2 {
    background-color: transparent;
    margin: 0 0 3rem 0;
    font-weight: 400;
}


.sidebar.open {
    left: 0; /* Slide the sidebar in */
  }

.sidebar > * {
    width: 60%;
}

.sidebar button[type="submit"] {
    cursor: pointer;
    background-color: black;
    color: white;
    padding: 0.25rem 1rem;
}

.sidebar button[type="submit"]:hover {
    background-color: #feeb22;
    color: black;
}

.sidebar {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sidebar form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.sidebar form > input, .sidebar .button-group {
    margin: 0 0 2rem 0;
}

.sidebar form > label {
    margin: 0 0 0.5rem 0;
}


.sidebar .call-to-action button{
    font-weight: bold;
    text-decoration: underline;
}

.sidebar .call-to-action {
    text-align: left;
    line-height: 1.5rem;
}

/* Login sidebar specific */
/* ---------------------- */
.login-sidebar .button-group {
    display: flex;
    justify-content: space-between;
}

.login-sidebar .create-account {
    text-align: left;
}

/* Cart sidebar specific */
/* --------------------- */
.right-sidebar {
    position: fixed;
    top: 0;
    right: -33%;
    width: 33%;
    height: 100vh;
    background-color: #f2f1f2;
    transition: right 0.3s ease-in-out;
    z-index: 110;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}

.right-sidebar > * {
    margin: 2rem;
}

.right-sidebar.open {
    right: 0%; /* Slide the sidebar in */
  }

.right-sidebar h2 {
    background-color: transparent;
    font-weight: 400;
    padding: 0;
}

.right-sidebar .total {
    display: flex;
    justify-content: space-between;
}

.right-sidebar .cart-info button {
    display: none;
}

.right-sidebar .cart-info > * {
    margin: 2rem 0;
}

.right-sidebar .bottom-info {
    margin: 3rem 2rem; 
}

.right-sidebar .bottom-info > * {
    margin: 2rem 0;
}

.right-sidebar .bottom-info button {
    padding: 1rem;
}
#login_dialog {
    box-sizing: border-box;
    text-align: center;
    padding: 0;
    border: none;
    z-index: 11;
    border-radius: 0.5rem;
}

#login_dialog .dialog__content {
    padding: 2rem;
}

#login_dialog h2 {
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
}

#login_dialog p {
    margin-bottom: 4rem;
}

#login_dialog button {
    padding: 0.5rem 1rem;
}



#login_dialog .selection-buttons {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

#login_dialog .selection-buttons button {
    margin: 0.5rem 0;
}

/* Input error checking */
/* -------------------- */

.error-input {
    border: 2px solid red;
}

#email-error-message {
    color: red;
}

#promo-code-form input {
    height: 2rem;
    margin-right: 1rem;
}

@media screen and (max-width: 1500px) {
    .right-sidebar {
        width: 45%;
        right: -45%;
    }
}

@media screen and (max-width: 950px) {
    .right-sidebar {
        width: 50%;
        right: -50%;
    }
}



@media screen and (max-width: 768px) {

    .sidebar {
        width: 100%;
        left: -100%;
    }

    .sidebar > button:last-child {
        display: flex;
        text-align: end;
        justify-content: flex-end;
        margin-top: 4rem;
        justify-self: end;
    }

    .sidebar > button:last-child:hover {
        background-color: transparent;
    }

    .sidebar > button:last-child svg {
        rotate: 180deg;
        margin-left: 1rem;
    }

    .cart-info button:first-child {
        display: flex;
    }

    .cart-info button:first-child svg {
        margin-right: 1rem;
    }

    .right-sidebar {
        right: -100%;
        width: 100%;
    }
}