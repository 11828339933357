#course-overview {
    padding-top: 4rem;
    margin-bottom: 2rem;
}

#course-overview .mobile {
    display: none;
}

/* #course-overview .content-wrapper {
    height: 100vh;
} */

.course-overview .content-wrapper > h2 {
    font-size: 3rem;
}

.course-overview h2 {
    /* font-family: 'Inconsolata', monospace; */
    background-color: transparent;
    display: block;
    text-align: center;
}

#course-overview h3 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-family: 'Helvetica-w01-light', sans-serif;
}

#course-overview .select-container {
    display: none;
}

#module-selection h3 {
    font-size: 1.5rem;
    font-family: 'Helvetica-w01-light', sans-serif;
}

#module-selection ul {
    list-style: none;
    padding: 0;
    margin-top: 2rem;
    height: 100%;
}

#module-selection li {
    border: 1px solid #000;
    padding: 1rem;
    line-height: 1.5;
}

#module-selection li:not(:first-child) {
    border-top: none;
}

#module-selection li:hover {
    background-color: #feeb22;
    cursor: pointer;
}

#module-selection > * {
    width: 100%;
}

.course-overview-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 100%;
    height: 100%;
    gap: 3rem;
}

#module-description h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
}

#module-description .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 2rem 4rem;
}

#module-description .content p {
    font-size: 1.2rem;
    line-height: 1.5;
}

#module-description .content p span {
    font-weight: bold;
}

#module-description .image-container {
    width: 100%;
    height: 350px;
    /* display: flex; */
}

#module-selection .select-container.active .option-container {
    max-height: 60vh;
}

#module-description .content img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1366px) and (orientation: portrait) {
    #course-overview .content-wrapper {
        height: fit-content;
    }
}
@media screen and (max-width: 820px) and (orientation: portrait) {
    .course-overview-content {
        grid-template-columns: 1fr;
    }
}


@media screen and (max-width: 1366px) {
    /* #course-overview .select-container {
        display: block;
    }

    #course-overview ul.desktop {
        display: none;
    } */

    /* #module-selection {
        height: 100%;
    }

    #course-overview .select-container {
        height: 100%;
    }

    #course-overview .select-container.active .option-container {
        max-height: 80%;
    } */

    /* #course-overview .select-container .option-container .option {
        height: 0px;
    } */

    #module-selection li {
        padding: 0.5rem;
        font-size: 0.8rem;
    }

    #module-description .content p {
        font-size: 1rem;
    }

    .course-overview .content-wrapper > h2 {
        font-size: 2rem;
    }

    #course-overview h3 {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
    #module-description .image-container {
        height: 300px;
    }
}

@media screen and (max-width: 1024px) {
    #module-description .image-container {
        height: 250px;
    }

    #course-overview .select-container {
        display: block;
    }

    #course-overview ul.desktop {
        display: none;
    }
}



@media screen and (max-width: 768px) {
    #module-selection .select-container.active .option-container {
        max-height: 90vh;
    }

    #course-overview .mobile {
        display: block;
    }

    #course-overview h3 {
        font-size: 1.25rem;
        margin-bottom: 2rem;
    }

    #course-overview .select-container {
        display: block;
    }

    .course-overview-content {
        grid-template-columns: 1fr;
    }

    #module-description .content {
        grid-template-columns: 1fr;
        text-align: center;
    }

    #course-overview .desktop {
        display: none;
    }
}