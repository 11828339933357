/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.page-section {
  margin-bottom: 4rem;
}

.item-name-price {
  text-align: center;
  align-self: center;
}

.back-to-btn {
  margin-top: 15rem;
  margin-bottom: 1rem;
}

/* for password page */

#password-and-signup {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

#password-page, #signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

#password-page img {
  height: 8rem;
}

.countdown-timer {
  width: fit-content;
  background-color: #282c34;
  color: white;
  font-size: 1.75rem;
  margin-top: 3rem;
  /* position: absolute; */
  /* bottom: 4rem; */
  /* left: 50%; */
  /* transform: translateX(-50%); */
  padding: 1rem;
  border-radius: 1rem;
  z-index: 1000;
  font-family: 'Helvetica-Neue', sans-serif;
}

.countdown-timer p {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .page-section {
    margin-bottom: 0;
  }
}

