.module-view {
    display: grid;
    grid-template-columns: 5fr 6fr;
    height: 100%;
    max-height: 100%;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.module-view > * {
    padding: 3rem 3rem 0 3rem;
}

.module-filters {
    margin-bottom: 1.5rem;
}

.module-filters .active {
    font-weight: bold;
}

.module-view h1 {
    margin-bottom: 2rem;
}

.module-view-left-side ul {
    /* display: flex; */
    display: none;
    list-style: none;
    padding: 0;
    max-height: 100vh;
}

.module-view-left-side li {
    height: 100%;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-right: 2rem;
    overflow-y: hidden;
}

.modules-container {
    width: 100%;
    max-height: 80vh;
    overflow-y: scroll;
    padding-bottom: 2rem;
}

.module-content {
    overflow-y: scroll;
    height: calc(100% - 3rem);
}

.module-content .video-player-wrapper {
    overflow: hidden;
    height: 450px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 2rem;
}

.module-content h1 {
    font-size: 2rem;
}

.module-content h2 {
    background-color: transparent;
    padding: 0;
    margin: 1rem 0;
}

#dashboard .module-view .headers button {
    display: flex;
}

#dashboard .module-content button.back-button, #dashboard .module-view .headers button {
    margin-bottom: 2rem;
    align-items: center;
    gap: 0.5rem;
}

@media screen and (max-width: 1366px) {
    .module-content .video-player-wrapper {
        height: 400px;
    }
}

@media screen and (orientation: portrait) and (min-width: 769px) and (max-width: 1024px) {
    .module-view > * {
        padding: 3rem 1rem 0 1rem;
    }
}

@media screen and (max-width: 768px) {
    .module-view {
        display: block;
    }

    .module-content .video-player-wrapper {
        height: 300px;
    }

    .module-view > *{
        padding: 3rem 1.5rem;
    }

    .modules-container {
        overflow-y: visible;
        max-height: fit-content;
    }

    #dashboard .module-content button.back-button, #dashboard .module-view .headers button {
        display: flex;
    }

    .module-content {
        position: fixed;
        z-index: 100;
        width: 100vw;
        top: 0;
        right: -100%;
        background-color: white;
        height: 89vh;
        transition: right 0.3s ease-in-out;
    }

    .module-content.desktop {
        display: none;
    }

    .module-content.active {
        right: 0;
    }
}