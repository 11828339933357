#receipt-section {
    margin: 15rem 5% 15rem 5%;
    font-family: 'Inconsolata', monospace;
    font-size: 1rem;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#receipt-section .receipt-header {
    display: flex;
    flex-direction: column;
}

#receipt-section .receipt-header h1 {
    font-size: 2.5rem;
    font-family: 'Inconsolata', monospace;
}

#receipt-section .receipt-header svg {
    width: fit-content;
    height: 5rem;
    margin-bottom: 4rem;
    align-self: center;
}

#receipt-section .receipt-header p {
    color: #666;
}
#receipt-section .receipt-header span {
    font-weight: 700;
    color: black;
}

#receipt-section .order-details h2 {
    font-size: 1.75rem;
    background-color: transparent;
    font-family: 'Inconsolata', monospace;
    margin-bottom: 1rem;
    padding: 0;
}

#receipt-section .order-details hr {
    display: inline-block;
    width: 100%;
    border: 1px solid #666;
}

#receipt-section .order-details-item {
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
}

#receipt-section .order-details-item.total {
    font-weight: 700;
    margin-top: 1rem;
}

#receipt-section button {
    font-size: 1.25rem;
    background-color: #feeb22;
    padding: 1rem;
    display: inline-block;
    width: fit-content;
    display: flex;
    gap: 1rem;
    align-items: center;
}

#receipt-section form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#receipt-section form input, #receipt-section form textarea {
    width: 100%;
}

#receipt-section button:hover {
    background-color: #fddc4a;
    cursor: pointer;
}

#receipt-section .report-card-form {
    background-color: var(--faq-item-bg-color);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
}

.report-card-form h2 {
    background-color: transparent;
}

@media screen and (max-width: 1024px) {
    #receipt-section {
        width: 100%;
    }

    #receipt-section .order-details-item {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    #receipt-section {
        margin: 10rem 0 0rem 0;
        padding: 0 5%;
    }
}