#course-banner {
    padding: 2rem;
    background-image: url('../../../public/coursebannerimg.png');
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    transform: translateX(-5%);
    margin-bottom: 0;
    margin-top: 0;
}

#course-banner-content {
    width: 90%;
}

#course-banner-content img {
    height: 8rem;
}

#course-banner-content h2 {
    font-size: 6rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

#course-banner-content h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    font-family: 'Helvetica-Neue', sans-serif;
    color: white;
    padding: 0;
}

#course-banner-content h3:nth-of-type(2) {
    font-size: 1.75rem;
    font-weight: 400;
}

#course-banner-content p {
    width: 50%;
    font-size: 1.1rem;
    line-height: 1.5;
}

#course-banner p {
    font-family: 'Helvetica-Neue', sans-serif;
    color: white;
    padding: 0;
}

#course-banner h2 {
    color: white;
    background-color: transparent;
    /* font-family: 'Inconsolata', monospace; */
    /* font-family: 'Helvetica-w01-light', sans-serif; */
    /* font-family: 'Helvetica-Neue', sans-serif; */
    font-family: 'Foundation Sans', sans-serif;
    padding: 0;
}

#course-banner hr {
    display: inline-block;
    width: 13ch;
    border: 2px solid white;
}

#select-financing-group {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 2rem 0;
    margin-bottom: 4rem;
}

#course-banner-content button {
    border-radius: 0.5rem;
    height: 50px;
    background-color: white;
    font-size: 1rem;
    padding: 0 1rem;
    transition: all 0.2s ease-out;
    font-family: 'Helvetica-w01-light', sans-serif;
}

#course-banner-content button:hover {
    background-color: #f0f0f0;
}

#course-banner-content .claim-btn {
    height: auto;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
}

#course-banner-content #interest-free {
    cursor: pointer;
}

@media screen and (max-width: 1366px) {
    #course-banner-content h2 {
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    #course-banner-content h3 {
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }

    #course-banner-content p {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 1200px) {
    #course-banner-content img {
        height: 6rem;
    }
    
    #course-banner-content h3 {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }

    #course-banner-content .claim-heading {
        margin-bottom: 1rem;
    }

    #course-banner-content p {
        font-size: 1rem;
    }

    #course-banner-content button, #course-banner-content .claim-btn {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 768px) {

    #course-banner {
        transform: translateX(-1rem);
        height: fit-content;
    }
    #course-banner-content {
        width: 100%;
    }

    #course-banner-content p {
        width: 90%;
    }

    #select-financing-group {
        flex-direction: column;
        align-items: flex-start;
    }

    #financing-dropdown {
        width: 50%;
    }
}

@media screen and (max-width: 500px) {
    #course-banner-content p {
        width: 100%;
    }

    #financing-dropdown {
        width: 100%;
    }
}