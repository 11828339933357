#preset-lut-view {
    /* box-sizing: border-box; */
    /* padding: 3rem; */
    height: 100%;
    width: 100%;
    display: grid;
    /* grid-template-columns: 3fr 2fr; */
    /* grid-template-columns: 1fr 35rem; */
}

#preset-lut-view h3 {
    font-weight: 400;
}

#preset-lut-view-left {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 3rem 0 3rem 3rem;

}

#user-owned-presets-luts h2, #user-unowned-presets-luts h2 {
    background-color: transparent;
    margin-left: 1rem;
    font-weight: 400;
}

/* #preset-lut-view-left > section {
    padding-left: 1rem;
} */

#user-owned-presets-luts {
    width: 100%;
    /* overflow-x: auto; */
    white-space: nowrap;
}

#user-unowned-presets-luts .preset-lut-card .preset-lut-card-image{
    /* make card look faded since it is unowned */
    opacity: 0.3;
}

.presets-luts-container {
    overflow-x: scroll;
    display: flex;
    height: 350px;
    align-items: center;
    padding: 0 1rem;
}

.presets-luts-container > div {
    margin-right: 3rem;
    display: inline-flex;
    flex-shrink: 0;
}

#preset-lut-view-right {
    box-sizing: border-box;
    background-color: white;
    height: 100%;
    /* width: auto; */
    padding: 4rem;
    /* right: -35rem; */
    right: -35rem;
    width: 35rem;
    position: absolute;
    transition: right 0.3s ease-in-out;
    overflow-y: scroll;
}

#preset-lut-view-right.open {
    right: 0;
}

#preset-lut-view-right h1 {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 0rem;
}

#preset-lut-view-right p {
    margin-top: 0.5rem;
    margin-bottom: 3rem;
}

.preset-lut-image-container {
    width: 100%;
    height: 300px;
    background-color: #777;
    border-radius: 30px;
    margin-bottom: 3rem;
    background-image: url("https://images.squarespace-cdn.com/content/v1/61a46cc601b4c521a42206d3/1645248050136-SZU0QNE11HSW5HMNKRIH/Night1-A.jpeg?format=1000w");
    background-size: cover;
    background-position: 50% 40%;
}

#preset-lut-size-info, #preset-lut-type-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

#preset-info {
    margin-bottom: 3rem;
}

#preset-lut-view-right button {
    padding: 0.5rem 1rem;
    background-color: #f2f1f2;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
}

#preset-lut-view-right button:hover {
    background-color: #e0e0e0;
    color: white;
}


#preset-lut-view-right button pre {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 0;
}

#preset-lut-view-right button:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

#preset-lut-view-right button:first-child svg {
    margin-right: 1rem;
}

@media screen and (max-width: 768px) {
    #preset-lut-view-left {
        height: 100%;
        padding: 3rem 0 3rem 1rem;
        margin: 0;
        display: block;
    }

    #dashboard .back-to-dashboard-home-btn {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }

    #dashboard .back-to-dashboard-home-btn svg {
        margin-right: 1rem;
    }

    #user-owned-presets-luts {
        margin-bottom: 3rem;
    }

    .presets-luts-container {
        height: auto;
    }

    #preset-lut-view-right {
        height: auto;
        width: 100vw;
        padding: 2rem;
    }

}