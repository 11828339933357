.my-profile-view {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 3rem;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    overflow-y: scroll;
}

.my-profile-view > * {
    margin-bottom: 2rem;
}

.my-profile-view .personal-info .save-button {
    display: none;
}

.my-profile-view .edit-button, .my-profile-view .save-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 2rem;
    display: flex;
    align-items: center;
    color: #777;
    padding: 0.5rem 1rem;
    border: 1px solid #777;
}

.my-profile-view .edit-button:hover {
    cursor: pointer;
    background-color: #777;
    color: white;
}

.my-profile-view .edit-button > span, .my-profile-view .save-button > span {
    margin-right: 0.5rem;
}

.name-and-email {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.name-and-email, .personal-info {
    padding: 2rem 1rem;
    border: 2px solid #999;
    border-radius: 10px;
    background-color: white;
    width: 60%;
}

.name-and-email svg {
    margin-right: 1rem;
    height: 3.5rem;
    width: auto;
}

.name-and-email p {
    margin-bottom: 0;
}

.personal-info {
    position: relative;
}

.personal-info input {
    display: none;
    font-size: 1rem;
    border: 2px solid #999;
    border-radius: 5px;
    padding: 0.25rem;

}

.personal-info h2 {
    background-color: transparent;
    padding: 0;
    font-size: 1.25rem;

}

.info-group {
    margin-right: 3rem;
}

.info-group > p:first-child {
    color: #777;
}

.first-and-last-name, .email-and-phone {
    display: flex;
}

@media (max-width: 980px) {
    .name-and-email, .personal-info {
        width: auto;
    }

    .info-group {
        margin-right: 0;
    }

    .first-and-last-name, .email-and-phone {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {

    .my-profile-view {
        padding: 3rem 2rem;
    }
    .name-and-email svg {
        margin-bottom: 1rem;
    }

    #name-and-email-text {
        width: 100%;
    }

    .my-profile-view .edit-button, .my-profile-view .save-button {
        position: relative;
        margin: 0;
        margin-top: 2rem;
        width: fit-content;
    }

    /* .name-and-email, .personal-info {
        width: 100%;
    }  */
}